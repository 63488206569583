@import './_colors.scss';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  background-color: #3d3d3d;
}

@media screen and (min-width: 1023px) {
  .container {
    padding-top: 370px;
  }
}

@media screen and (max-width: 1022px) {
  .container {
    padding-top: 210px;
  }
}
