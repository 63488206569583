iframe#modal-iframe {

}

.modal-iframe-wrapper {
    z-index: 2000;
}

.close-icon {
    font-weight: 600;
    font-size: 20px;
    position: fixed;
    top: 50px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    right: 20px;
    z-index: 3000;
    color: #fff;
}
